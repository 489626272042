<template>
	<div class="open-app" :class="{ 'dark-mode': darkMode }">
		<div class="container">
			<div class="header">
				<div class="top float-right">
					<a
						:href="app.webUrl"
						target="_blank"
						class="btn"
						:class="[
							{ 'btn-outline-dark': !darkMode },
							{ 'btn-outline-light': darkMode },
						]"
						title="Open app (new window)"
					>
						<i class="fas fa-external-link-alt"></i>
					</a>
					<button @click="$emit('close')" class="btn btn-outline-danger">
						<i class="fas fa-times"></i>
					</button>
				</div>
				<h2 class="app-name">
					{{ capFirst(app.name) }}
					<span
						class="status-indicator app-state"
						:title="getState(app)"
						:class="'state-' + getState(app).toLowerCase()"
					></span>
				</h2>
			</div>
			<div class="body">
				<div class="app-info">
					<div class="row">
						<div class="col">
							<span class="created">
								<strong>Created:</strong>
								{{ dateFormat(app.createdAtString, 'DD.MM.YYYY - hh:mm') }}
							</span>
						</div>
						<div class="col">
							<span class="updated">
								<strong>Updated:</strong>
								{{ dateFormat(app.updatedAtString, 'DD.MM.YYYY - hh:mm') }}
							</span>
						</div>
						<div class="col">
							<span class="released">
								<strong>Released:</strong>
								{{ dateFormat(app.releasedAtString, 'DD.MM.YYYY - hh:mm') }}
							</span>
						</div>
					</div>
					<table class="table table-striped">
						<tbody>
							<tr class="ssl">
								<th>SSL</th>
								<td>
									<span
										class="status-indicator"
										:class="'state-' + app.acm"
										:title="app.acm"
									></span>
								</td>
							</tr>
							<tr class="server-location">
								<th>Server location</th>
								<td>
									<span>{{ app.regionName }}</span>
								</td>
							</tr>
							<tr class="buildStack">
								<th>Build stack name</th>
								<td>
									<span>{{ app.buildStackName }}</span>
								</td>
							</tr>
							<tr class="uptime-percentage">
								<th>Uptime</th>
								<td>
									<span>{{ uptimePercentage }}</span>
								</td>
							</tr>
							<tr class="dynos-running">
								<th>Dynos running</th>
								<td>
									<span
										><toggle-button
											:labels="{ checked: 'On', uncheked: 'Off' }"
											:sync="true"
											v-model="app.dynoRunning"
											@change="toggleDisable(app)"
									/></span>
								</td>
							</tr>
							<tr class="ignore">
								<th>Ignore</th>
								<td>
									<span
										><toggle-button
											:labels="{ checked: 'On', uncheked: 'Off' }"
											:sync="true"
											v-model="app.ignored"
											@change="toggleIgnore(app)"
									/></span>
								</td>
							</tr>
							<tr class="maintenance">
								<th>Maintenance</th>
								<td>
									<span
										><toggle-button
											:labels="{ checked: 'On', uncheked: 'Off' }"
											:sync="true"
											v-model="app.maintenance"
											@change="toggleMaintenance(app)"
									/></span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="downtimes">
					<h5>Downtimes</h5>
					<table
						class="table table-striped"
						v-if="app.downtimes && app.downtimes.length"
					>
						<thead>
							<tr>
								<th>Down</th>
								<th>Up</th>
								<th>Length</th>
								<th>Restarted</th>
								<th>EmailSent</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="dt in app.downtimes" :key="dt.id">
								<td>
									<span v-if="dt.down">{{
										dateFormat(dt.down, 'DD.MM.YYYY - HH:mm')
									}}</span>
								</td>
								<td>
									<span v-if="dt.up">{{
										dateFormat(dt.up, 'DD.MM.YYYY - HH:mm')
									}}</span>
								</td>
								<td>
									<span v-if="dt.down && dt.up">{{
										timeBetween(dt.down, dt.up)
									}}</span>
								</td>
								<td>
									<span
										class="status-indicator downtime-restart-state"
										:title="dt.restarted"
										:class="'state-' + dt.restarted"
									></span>
								</td>
								<td>
									<span
										class="status-indicator downtime-email-sent-state"
										:title="dt.emailSent"
										:class="'state-' + dt.emailSent"
									></span>
								</td>
							</tr>
						</tbody>
					</table>
					<div class="no-downtimes" v-else>
						<p>No downtimes recorded</p>
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="float-right">
					<button @click="$emit('close')" class="btn btn-outline-danger">
						<i class="fas fa-times"></i>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button'
import { mapState } from 'vuex'

export default {
	name: 'open-app',
	props: {
		app: Object,
		runningSince: String,
	},
	components: {
		ToggleButton,
	},
	methods: {
		dateFormat(dateTime, format) {
			return this.$moment(dateTime).format(format)
		},
		capFirst(text) {
			return text.charAt(0).toUpperCase() + text.slice(1)
		},
		getState(app) {
			return app.maintenance ? 'Maintenance' : app.crashed ? 'Crashed' : 'Up'
		},
		timeBetween(start, end) {
			let from = this.$moment(start)
			let to = this.$moment(end)
			let dur = this.$moment.duration(to.diff(from))
			if (dur.asDays() > 1) {
				return (
					dur.days() +
					' days, ' +
					dur.hours() +
					' hours, ' +
					dur.minutes() +
					' minutes'
				)
			} else if (dur.asHours() >= 1) {
				return dur.hours() + ' hours, ' + dur.minutes() + ' minutes'
			} else if (dur.asMinutes() >= 1) {
				return dur.minutes() + ' minutes'
			}
			return dur.asSeconds()
		},
		toggleIgnore(app) {
			this.$emit('toggleIgnore', app, app.ignored)
		},
		toggleMaintenance(app) {
			this.$emit('toggleMaintenance', app, app.maintenance)
		},
		toggleDisable(app) {
			this.$emit('toggleDisable', app, !app.dynoRunning)
		},
	},
	computed: {
		...mapState(['darkMode']),
		uptimePercentage() {
			let rs = this.$moment(this.runningSince)
			let created = this.$moment(this.app.createdAtString)
			let last = rs > created ? rs : created
			if (!this.app.downtimes || !this.app.downtimes.length) {
				return '100% since ' + last.format('DD.MM.YYYY')
			}
			let now = this.$moment()
			let diff = now - last
			let sumDt = this.app.downtimes
				.map(dt => {
					if (!dt.up) {
						return now - this.$moment(dt.down)
					} else {
						return this.$moment(dt.up) - this.$moment(dt.down)
					}
				})
				.reduce((acc, cur) => acc + cur)
			return (
				(100 - (sumDt / diff) * 100).toFixed(2) +
				'% since ' +
				last.format('DD.MM.YYYY')
			)
		},
	},
}
</script>

<style lang="less" scoped>
.status-indicator {
	height: 25px;
	width: 25px;
	border-radius: 50%;
	display: inline-block;
	&.state-up,
	&.state-true {
		background-color: #28a745;
	}
	&.state-maintenance {
		background-color: #007bff;
	}
	&.state-crashed,
	&.state-false {
		background-color: #dc3545;
	}
}

.open-app {
	&.dark-mode {
		tr {
			color: #fff;
		}
	}
	margin: 20px 10px 60px 10px;
	.body {
		.app-info {
			table {
				margin-top: 15px;
			}
			strong {
				padding-right: 5px;
			}
		}
		.status-indicator {
			height: 15px;
			width: 15px;
		}
	}
}
</style>
