<template>
	<div class="modal-content">
		<div class="modal-header">
			<h3>Please log in</h3>
		</div>
		<div class="modal-body">
			<div class="username">
				<label class="control-label" for="login-username"
					><strong>Username</strong></label
				>
				<input
					id="login-username"
					class="form-control"
					type="text"
					v-model="username"
					@keydown.enter="login"
				/>
			</div>
			<div class="password">
				<label class="control-label" for="login-password"
					><strong>Password</strong></label
				>
				<input
					id="login-password"
					class="form-control"
					type="password"
					v-model="password"
					@keydown.enter="login"
				/>
			</div>
		</div>
		<div class="modal-footer">
			<button @click="login">Login</button>
			<button @click="$emit('close')">Close</button>
		</div>
	</div>
</template>

<script>
import OctiEye from '@/services/OctiEye'

export default {
	name: 'login-modal',
	data() {
		return {
			username: '',
			password: '',
		}
	},
	methods: {
		async login() {
			let formData = new FormData()
			formData.append('username', this.username)
			formData.append('password', this.password)
			await OctiEye.login(formData)
				.then(response => {
					// console.log(response)
					if (response.data.status === 'success') {
						this.$store.dispatch('setToken', response.data.data.loginToken)
						this.$emit('close')
					} else {
						this.$notify.error(response.data.message)
					}
				})
				.catch(error => {
					this.$notify.error(error.response.data.message)
				})
		},
	},
}
</script>

<style lang="less" scoped>
.modal-header {
	background-color: #343a40;
	color: #fff;
}
</style>
