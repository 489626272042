<template>
	<div class="app-list" :class="{ 'dark-mode': darkMode }">
		<div class="filters-and-sort">
			<div class="row">
				<div class="col">
					<div class="card" :class="{ 'text-white bg-dark': darkMode }">
						<div class="card-header">Serach</div>
						<div class="card-body">
							<div class="input-group">
								<input
									type="text"
									class="form-control"
									placeholder="App name"
									aria-label="App name"
									v-model="textSearch"
								/>
								<div class="input-group-append" v-if="textSearch">
									<button
										class="btn"
										type="button"
										:class="[
											{ 'btn-outline-dark': !darkMode },
											{ 'btn-outline-light': darkMode },
										]"
										@click="textSearch = ''"
									>
										<i class="fas fa-times"></i>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col">
					<div class="card" :class="{ 'text-white bg-dark': darkMode }">
						<div class="card-header">Status</div>
						<div class="card-body">
							<div class="row">
								<div class="col">
									<label>Show Up</label>
									<br />
									<toggle-button
										:labels="{ checked: 'On', uncheked: 'Off' }"
										:sync="true"
										v-model="showUp"
									/>
								</div>
								<div class="col">
									<label>Show Crashed</label>
									<br />
									<toggle-button
										:labels="{ checked: 'On', uncheked: 'Off' }"
										:sync="true"
										v-model="showCrashed"
									/>
								</div>
								<div class="col">
									<label>Show Maintenance</label>
									<br />
									<toggle-button
										:labels="{ checked: 'On', uncheked: 'Off' }"
										:sync="true"
										v-model="showMaintenance"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="apps-info">
			<span
				>Showing
				{{ appsFiltered.length == apps.length ? 'all' : appsFiltered.length }}
				of the {{ apps.length }} total apps.</span
			>
		</div>
		<table class="table">
			<thead>
				<tr>
					<th>Name</th>
					<th>Status</th>
					<th>Ignore</th>
					<th>Show app</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="app in appsFiltered"
					:key="app.id"
					class="app"
					:class="'status-' + getStatus(app).toLowerCase()"
				>
					<td>{{ app.name }}</td>
					<td>{{ getStatus(app) }}</td>
					<td>
						<toggle-button
							:labels="{ checked: 'On', uncheked: 'Off' }"
							:sync="true"
							v-model="app.ignored"
							@change="toggleIgnore(app)"
						/>
					</td>
					<td>
						<button
							@click="$emit('openApp', app)"
							class="btn"
							:class="[
								{ 'btn-outline-dark': !darkMode },
								{ 'btn-outline-light': darkMode },
							]"
						>
							<i class="far fa-window-restore"></i>
						</button>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button'
import { mapState } from 'vuex'

export default {
	name: 'appList',
	data() {
		return {
			textSearch: '',
			status: 'active',
			order: 'name',
			orderReversed: false,
			showUp: true,
			showCrashed: true,
			showMaintenance: true,
		}
	},
	props: {
		apps: Array,
	},
	components: {
		ToggleButton,
	},
	methods: {
		getStatus(app) {
			return app.maintenance ? 'Maintenance' : app.crashed ? 'Crashed' : 'Up'
		},
		toggleIgnore(app) {
			this.$emit('toggleIgnore', app, app.ignored)
		},
	},
	computed: {
		...mapState(['darkMode']),
		appsFiltered() {
			var list = this.apps.slice(0)

			if (!this.showMaintenance) {
				list = list.filter(app => !app.maintenance)
			}
			if (!this.showCrashed) {
				list = list.filter(app => !app.crashed)
			}
			if (!this.showUp) {
				list = list.filter(app => app.maintenance || app.crashed)
			}
			if (this.textSearch) {
				const searchLower = this.textSearch.toLowerCase()
				list = list.filter(
					app => app.name.toLowerCase().indexOf(searchLower) > -1
				)
			}
			list = list.sort((a, b) => {
				if (a[this.order] < b[this.order]) return -1
				if (a[this.order] < b[this.order]) return 1
				return 0
			})
			if (this.orderReversed) {
				list = list.reverse()
			}
			return list
		},
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.app-list {
	&.dark-mode {
		tr {
			color: #fff;
		}
		.app {
			&.status-maintenance {
				background-color: #ffc107;
				border-color: #ffc107;
				color: #000;
			}
			&.status-crashed {
				background-color: #dc3545;
				border-color: #dc3545;
				color: #000;
			}
		}
	}
	table {
		tbody {
			.app {
				&.status-maintenance {
					background-color: #fff3cd;
					border-color: #ffeeba;
					tr {
						color: #000;
					}
				}
				&.status-crashed {
					background-color: #f8d7da;
					border-color: #f5c6cb;
					tr {
						color: #000;
					}
				}
			}
		}
	}
}
</style>
