import axios from 'axios'
import store from '@/store/index'

let baseURL = process.env.VUE_APP_OCTI_EYE_API_BASE_URL

const Service = axios.create({
	baseURL: baseURL,
	headers: {
		token: store.state.loginToken ? store.state.loginToken.token : null,
	},
})

Service.interceptors.request.use(config => {
	config.headers['token'] = store.state.loginToken
		? store.state.loginToken.token
		: null
	return config
})

Service.interceptors.response.use(
	response => {
		return response
	},
	error => {
		// console.log(error.response)
		if (error.response.status == 401 && error.response.config.url != '/login') {
			// console.log('401')
			store.dispatch('setToken', null)
			store.commit('setShowLogin', true)
		}
		return Promise.reject(error)
	}
)

export default {
	// Authentication
	login(formData) {
		return Service.post('/login', formData)
	},
	logout() {
		return Service.post('/logout')
	},
	logoutAll() {
		return Service.post('/logout-all')
	},

	//Configuration
	updateSendMails(value) {
		return Service.put('/sendMails', null, {
			params: {
				sendMails: value,
			},
		})
	},

	// Apps
	fetchApps() {
		return Service.get('/apps')
	},
	forceUpdateApps() {
		return Service.get('/apps/update')
	},
	toggleIgnore(appId, value) {
		return Service.put('/apps/' + appId + '/ignore', null, {
			params: {
				ignored: value,
			},
		})
	},
	toggleMaintenance(appId, value) {
		return Service.put('/apps/' + appId + '/maintenance', null, {
			params: {
				maintenance: value,
			},
		})
	},
	toggleDisable(appId, value) {
		return Service.put('/apps/' + appId + '/disabled', null, {
			params: {
				disabled: value,
			},
		})
	},
	openApp(appId) {
		return Service.get('/apps/' + appId)
	},
}
