var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header header-dark"},[_c('ul',{staticClass:"pages"},[_c('router-link',{staticClass:"navbar-brand",attrs:{"to":"/"}},[_c('h3',[_c('i',{staticClass:"far fa-eye"}),_vm._v(" Octi Eye ")])]),_c('router-link',{attrs:{"to":"/"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{class:[
					isActive && 'router-link-active',
					isExactActive && 'active router-link-exact-active' ]},[_c('a',{staticClass:"nav-link",attrs:{"href":href},on:{"click":navigate}},[_vm._v("Home")])])]}}])}),_c('router-link',{attrs:{"to":"/monitor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
				var href = ref.href;
				var navigate = ref.navigate;
				var isActive = ref.isActive;
				var isExactActive = ref.isExactActive;
return [_c('li',{class:[
					isActive && 'router-link-active',
					isExactActive && 'active router-link-exact-active' ]},[_c('a',{staticClass:"nav-link",attrs:{"href":href},on:{"click":navigate}},[_vm._v("Monitor")])])]}}])}),(_vm.loginToken)?_c('li',{staticClass:"float-right log-out"},[_c('a',{staticClass:"nav-link",on:{"click":_vm.logout}},[_vm._v("Log out")])]):_vm._e(),_c('li',{staticClass:"float-right dark-mode-toggle"},[_c('a',{staticClass:"nav-link",on:{"click":function($event){return _vm.toggleDarkMode(!_vm.darkMode)}}},[(_vm.darkMode)?_c('i',{staticClass:"far fa-lightbulb"}):_c('i',{staticClass:"fas fa-lightbulb"})])]),_c('li',{staticClass:"float-right sounds-toggle"},[_c('a',{staticClass:"nav-link",on:{"click":function($event){return _vm.toggleSounds(!_vm.sounds)}}},[(_vm.sounds)?_c('i',{staticClass:"fas fa-volume-up"}):_c('i',{staticClass:"fas fa-volume-mute"})])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }