import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Bootstrap from 'bootstrap-vue'
import VModal from 'vue-js-modal'
import Notify from 'vue2-notify'
import VueTimers from 'vue-timers'

Vue.use(require('vue-moment'))
Vue.use(Bootstrap)
Vue.config.productionTip = false
Vue.use(VModal, { dynamic: true, injectModalsContainer: true })
Vue.use(Notify, { visibility: 5000, position: 'top-right' })
Vue.use(VueTimers)
new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
