import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		loginToken: localStorageParse('loginToken'),
		showLogin: false,
		darkMode: localStorageParse('darkMode'),
		sounds: localStorageParse('sounds'),
	},
	mutations: {
		setLoginToken(state, payload) {
			state.loginToken = payload
		},
		setShowLogin(state, payload) {
			state.showLogin = payload
			if (payload) {
				Vue.prototype.$modal.show('login-modal')
			} else {
				Vue.prototype.$modal.hide('login-modal')
			}
		},
		setDarkMode(state, payload) {
			state.darkMode = payload
		},
		setSounds(state, payload) {
			state.sounds = payload
		},
	},
	actions: {
		setToken({ commit }, newToken) {
			if (newToken) {
				localStorage.setItem('loginToken', JSON.stringify(newToken))
			} else {
				localStorage.removeItem('loginToken')
			}
			commit('setLoginToken', newToken)
		},
		setDark({ commit }, value) {
			localStorage.setItem('darkMode', JSON.stringify(value))
			commit('setDarkMode', value)
		},
		setSound({ commit }, value) {
			localStorage.setItem('sounds', JSON.stringify(value))
			commit('setSounds', value)
		},
	},
	modules: {},
})

function localStorageParse(item) {
	return localStorage.getItem(item)
		? JSON.parse(localStorage.getItem(item))
		: null
}
