<template>
	<div class="home">
		<div class="loading" v-if="loading">
			<loading
				:active.sync="loading"
				color="#fff"
				:is-full-page="true"
				background-color="#343a40"
				loader="dots"
				:opacity="0.9"
				:z-index="50"
			/>
		</div>
		<div class="error" v-else-if="error">
			<div class="card" :class="{ 'text-white bg-dark': darkMode }">
				<div class="card-header">
					<h3>{{ error }}</h3>
				</div>
				<div class="card-body">
					<button class="btn btn-outline-dark" @click="fetchApps">
						Retry connection
					</button>
				</div>
			</div>
		</div>
		<div class="loaded" v-else>
			<div class="settings">
				<div class="card" :class="{ 'text-white bg-dark': darkMode }">
					<div class="card-header">Settings</div>
					<div class="card-body">
						<div class="row">
							<div class="col">
								<div class="alert alert-danger" v-if="!sendMails">
									<h3>Mails are deactivated</h3>
									<p>
										No mails will be sent when apps go
										<strong>up</strong>
										or
										<strong>down</strong>
									</p>
								</div>
								<label>Send mails</label>
								<br />
								<toggle-button
									:labels="{ checked: 'On', uncheked: 'Off' }"
									:sync="true"
									v-model="sendMails"
									:color="{ unchecked: '#dc3545' }"
									@change="updateSendMails"
								/>
							</div>
							<div class="col">
								<div class="alert alert-dark">
									<p>
										Octi Eye has been running since:
										<strong>{{
											dateFormat(runningSince, 'DD.MM.YYYY HH:mm')
										}}</strong>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="apps" v-if="apps && apps.length">
				<app-list
					:apps="apps"
					@toggleIgnore="toggleIgnore"
					@openApp="openApp"
				/>
			</div>
			<div
				class="card no-apps"
				:class="{ 'text-white bg-dark': darkMode }"
				v-else
			>
				<div class="card-header">
					<h3>No apps found</h3>
				</div>
				<div class="card-body">
					<button class="btn btn-outline-dark" @click="forceUpdateApps">
						Run update apps job
					</button>
				</div>
			</div>
		</div>
		<modal
			name="open-app"
			height="auto"
			:clickToClose="false"
			:scrollable="true"
			width="1000px"
			:adaptive="true"
		>
			<open-app
				:app="fullApp"
				@close="closeApp"
				:runningSince="runningSince"
				@toggleIgnore="toggleIgnore"
				@toggleMaintenance="toggleMaintenance"
				@toggleDisable="toggleDisable"
			/>
		</modal>
	</div>
</template>

<script>
// @ is an alias to /src
import AppList from '@/components/AppList.vue'
import OctiEye from '@/services/OctiEye'
import { ToggleButton } from 'vue-js-toggle-button'
import OpenApp from '@/components/OpenApp'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import Vue from 'vue'
import { mapState } from 'vuex'

export default {
	name: 'home',
	data() {
		return {
			apps: [],
			sendMails: true,
			fullApp: null,
			loading: true,
			runningSince: '',
			error: null,
		}
	},
	components: {
		AppList,
		ToggleButton,
		OpenApp,
		Loading,
	},
	computed: {
		...mapState(['loginToken', 'darkMode']),
	},
	created() {
		this.fetchApps()
	},
	methods: {
		fetchApps() {
			this.loading = true
			OctiEye.fetchApps()
				.then(response => {
					if (response.data.success) {
						this.apps = response.data.data.apps
						this.sendMails = response.data.data.sendMails
						this.runningSince = response.data.data.runningSince
						this.error = null
					}
					this.loading = false
				})
				.catch(error => {
					this.loading = false
					this.error = error
					this.$notify.error(error)
				})
		},
		forceUpdateApps() {
			this.loading = true
			OctiEye.forceUpdateApps()
				.then(response => {
					if (response.data.success) {
						this.apps = response.data.data.apps
						this.error = null
					}
					this.loading = false
				})
				.catch(error => {
					this.loading = false
					this.error = error
					this.$notify.error(error)
				})
		},
		updateSendMails() {
			OctiEye.updateSendMails(this.sendMails).then(response => {
				this.$notify(response.data.message, response.data.status.toLowerCase())
			})
		},
		async toggleIgnore(app, value) {
			OctiEye.toggleIgnore(app.id, value).then(response => {
				this.$notify(response.data.message, response.data.status.toLowerCase())
				// console.log(response)
				if (response.data.success) {
					this.replaceApp(response.data.data.app)
				}
			})
		},
		async toggleMaintenance(app, value) {
			OctiEye.toggleMaintenance(app.id, value).then(response => {
				this.$notify(response.data.message, response.data.status.toLowerCase())
				// console.log(response)
				if (response.data.success) {
					this.replaceApp(response.data.data.app)
				}
			})
		},
		async toggleDisable(app, value) {
			OctiEye.toggleDisable(app.id, value).then(response => {
				this.$notify(response.data.message, response.data.status.toLowerCase())
				// console.log(response)
				if (response.data.success) {
					this.replaceApp(response.data.data.app)
					this.fullApp = response.data.data.app
				}
			})
		},
		async openApp(app) {
			await OctiEye.openApp(app.id).then(response => {
				if (response.data.status == 'success') {
					this.fullApp = response.data.data.app
					this.$modal.show('open-app')
				}
			})
		},
		closeApp() {
			this.fullApp = null
			this.$modal.hide('open-app')
		},
		dateFormat(dateTime, format) {
			return this.$moment(dateTime).format(format)
		},
		replaceApp(app) {
			Vue.set(this.apps, this.apps.findIndex(a => a.id === app.id), app)
		},
	},
	watch: {
		loginToken(value) {
			if (value) {
				this.fetchApps()
			}
		},
	},
}
</script>

<style lang="less" scoped></style>
