<template>
	<div id="app" :class="{ 'dark-mode': darkMode }">
		<TopBar />
		<div class="container">
			<router-view></router-view>
		</div>
		<modal
			name="login-modal"
			:clickToClose="false"
			height="auto"
			:adaptive="true"
		>
			<loginModal @close="$store.commit('setShowLogin', false)" />
		</modal>
	</div>
</template>

<script>
import loginModal from '@/components/loginModal'
import TopBar from '@/components/TopBar'
import { mapState } from 'vuex'

export default {
	components: {
		loginModal,
		TopBar,
	},
	computed: {
		...mapState(['loginToken', 'showLogin', 'darkMode']),
	},
	watch: {},
}
</script>

<style lang="less">
#app {
	&.dark-mode {
		background-color: #222;
		background-size: cover;
		background-position: bottom;
		min-height: 100vh;
		color: #fff;

		.v--modal {
			background-color: #222;
		}
	}

	nav.navbar {
		z-index: 55;
	}
	.container {
		padding-top: 10px;
		// max-width: 80%;
		@media (min-width: 1500px) {
			max-width: 1400px;
		}
		@media (min-width: 1900px) {
			max-width: 1800px;
		}
		@media (min-width: 2300px) {
			max-width: 2200px;
		}
	}
	.v--modal-overlay {
		z-index: 99;
	}
}
.card {
	margin-bottom: 10px;
}
.v--modal-overlay {
	.v--modal-background-click {
		.v--modal {
			border-radius: 0.3rem;
		}
	}
}
</style>
