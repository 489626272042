<template>
	<div class="header header-dark">
		<ul class="pages">
			<router-link to="/" class="navbar-brand">
				<h3>
					<i class="far fa-eye"></i>
					Octi Eye
				</h3>
			</router-link>
			<router-link to="/" v-slot="{ href, navigate, isActive, isExactActive }">
				<li
					:class="[
						isActive && 'router-link-active',
						isExactActive && 'active router-link-exact-active',
					]"
				>
					<a class="nav-link" :href="href" @click="navigate">Home</a>
				</li>
			</router-link>
			<router-link
				to="/monitor"
				v-slot="{ href, navigate, isActive, isExactActive }"
			>
				<li
					:class="[
						isActive && 'router-link-active',
						isExactActive && 'active router-link-exact-active',
					]"
				>
					<a class="nav-link" :href="href" @click="navigate">Monitor</a>
				</li>
			</router-link>
			<li class="float-right log-out" v-if="loginToken">
				<a class="nav-link" @click="logout">Log out</a>
			</li>
			<li class="float-right dark-mode-toggle">
				<a class="nav-link" @click="toggleDarkMode(!darkMode)">
					<i v-if="darkMode" class="far fa-lightbulb"></i>
					<i v-else class="fas fa-lightbulb"></i>
				</a>
			</li>
			<li class="float-right sounds-toggle">
				<a class="nav-link" @click="toggleSounds(!sounds)">
					<i v-if="sounds" class="fas fa-volume-up"></i>
					<i v-else class="fas fa-volume-mute"></i>
				</a>
			</li>
		</ul>
	</div>
</template>

<script>
import OctiEye from '@/services/OctiEye'
import router from '@/router'
import { mapState } from 'vuex'

export default {
	name: 'TopBar',
	computed: {
		...mapState(['loginToken', 'darkMode', 'sounds']),
	},
	methods: {
		async logout() {
			await OctiEye.logout()
				.then(response => {
					if (response.data.status === 'success') {
						this.$store.dispatch('setToken')
						router.push({ name: 'home' })
					}
				})
				.catch(error => {
					this.$notify.error(error.message)
				})
		},
		toggleDarkMode(value) {
			this.$store.dispatch('setDark', value)
		},
		toggleSounds(value) {
			this.$store.dispatch('setSound', value)
		},
	},
}
</script>

<style lang="less" scoped>
.header {
	&.header-dark {
		background-color: #343a40;
		color: #fff;
		li {
			list-style: none;
			display: inline-flex;
			&.active {
				background-color: #616b75;
				border-radius: 5px;
			}
			&:hover {
				background-color: #474e55;
				border-radius: 5px;
			}
		}
		a {
			text-decoration: none;
			color: #fff;
		}
		.log-out,
		.dark-mode-toggle,
		.sounds-toggle {
			margin-top: 5px;
			margin-right: 10px;
			cursor: pointer;
		}
	}
}
</style>
